<template>
  <div>
    <div class="e-breadcrumb">权限配置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class=" user-container">
            <div class="tree-container">
              <v-tree
                  v-loading="treeLoading"
                  :nodes="nodes"
                  :current-change="currentRow"
              >
              </v-tree>
            </div>
            <div class="order-table-wrapper" v-if="departmentId">
              <div class="e-card">
                <div class="e-card-body" style="padding-top:35px">
                  <div class="e-handle-box" style="margin-bottom: 50px">
                    <div class="handle-box">
                      <span class="v-form-label">用户名称:</span>
                      <el-input placeholder="请输入用户名称" size="small" style="width:265px" v-model="userName"
                                clearable
                                class="handle-input mr10"></el-input>
                      <span class="v-form-label">用户状态:</span>
                      <el-select v-model="isForbidden" size="small" style="width:265px" placeholder="请选择用户状态" clearable>
                        <el-option
                            v-for="item in stateItem"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                      </el-select>
                      <div style="margin-left: 180px">
                        <el-row type="flex" justify="end">
                          <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                          </el-button>
                          <el-button size="small" class="e-button" type="primary" @click="addEvent">新增
                          </el-button>
                        </el-row>
                      </div>
                    </div>
                  </div>
                  <div class="e-table-wrapper" style="margin-top: -15px">
                    <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
                      <thead>
                      <tr>
                        <th width="150" class="th-l">用户名</th>
                        <th width="150" class="th-l">状态</th>
                        <th width="130" class="th-l">类别</th>
                        <th width="130" class="th-l">邮箱</th>
                        <th width="130" class="th-l">创建时间</th>
                        <th width="400" class="th-l">操作</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                        <td class="td-l"><span>{{row.loginName}}</span></td>
                        <td class="td-l">{{row.isForbidden? '停用' : '启用'}}</td>
                        <td class="td-l" v-if="row.sort==1">门店</td>
                        <td class="td-l" v-else-if="row.sort==2">前台</td>
                        <td class="td-l" v-else></td>
                        <td class="td-l">{{row.email}}</td>
                        <td class="td-l"><span>{{row.createTime}}</span></td>
                        <td class="td-l">
                          <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                          <el-button type="text" icon="el-icon-edit" @click="showRole(index)">分配角色</el-button>
                          <el-button v-if="row.isForbidden" type="text" icon="el-icon-circle-check-outline"
                                     @click="statusEvent(index)">启用
                          </el-button>
                          <el-button v-if="!row.isForbidden" class="warning" type="text"
                                     icon="el-icon-circle-close-outline" @click="statusEvent(index)">停用
                          </el-button>
                          <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除
                          </el-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="pagination">
                      <el-pagination
                          background
                          @current-change="handleCurrentChange"
                          :pager-count="7"
                          layout="total, prev, pager, next,jumper"
                          :total="pagination.count"
                          :page-count="pagination.pages"
                          :current-page="pagination.pageNumber"
                      ></el-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addUserSuccess="addUserSuccess"
            :roleItem="roleItem" :departmentId="departmentId" :departmentCode="departmentCode" :options ="departmentOptions"/>
    <Edit v-if="editVisible" :editVisible="editVisible" @closeEdit="closeEdit" @editSuccess="editUserSuccess"
          :editItem="editItem" :roleItem="roleItem" :options ="departmentOptions" :departmentId="departmentId"/>
    <AddRole v-if="roleVisible" :roleVisible="roleVisible" :id="currentId" :roles="roles" @hideRoleEvent="hideRoleEvent"/>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delUser">确 定</el-button>
        <el-button size="small" @click="delVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 启用/禁用提示框 -->
    <el-dialog title="提示" :visible.sync="enableVisible" width="300px" center>
      <div class="del-dialog-cnt">是否启用？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="enableUser">确 定</el-button>
        <el-button size="small" @click="enableVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="prohibitVisible" width="300px" center>
      <div class="del-dialog-cnt">是否禁用？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="prohibitUser">确 定</el-button>
        <el-button size="small" @click="prohibitVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Insert from './insert'
import Edit from './edit'
import AddRole from './add_role'
import vTree from '@/components/common/v-tree/tree'
import {getUserList, getRoleList, deleteUser, getDictDetailList, userStatus,getDepartmentList} from '../../../api/sys/user'
import {setDictMap,dataFormat} from '../../../utils/main.js'

export default {
    data() {
        return {
            treeLoading: false,
            tableLoading: false,
            nodes: [],
            userName: "",
            roles:[],
            isForbidden: "",
            stateItem: [
                {
                    label: "请选择状态",
                    value: ""
                },
                {
                    label: "启用",
                    value: "0"
                },
                {
                    label: "禁用",
                    value: "1"
                }
            ],
            list: [],
            pagination: {
                count: 0,
                pageNumber: 0,
                pageSize: 0,
                pages: 1
            },
            addVisible: false,
            editVisible: false,
            delVisible: false,
            enableVisible: false,
            prohibitVisible: false,
            roleVisible: false,
            editItem: {},
            roleItem: [],
            selItem: {},
            statusMap: {},
            options: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId",
                    },
                    key: {
                        name: "departmentName",
                        children: 'children'
                    }
                }
            },
            departmentOptions: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId",
                    },
                    key: {
                        name: "departmentName",
                        children: 'children'
                    }
                }
            },
            titles:['组织名称'],
            btns:[],
            departmentCode: '',
            departmentId: ''
        };
    },
    async created() {
        this.roleList();
        this.getDictDetailList();
        this.departmentList()
    },
    methods: {
        currentRow(item){
            this.userName=''
            this.isForbidden=''
            this.departmentCode = item.departmentCode
            this.departmentId = item.id
            this.searchEvent()
        },

        handleCurrentChange(page) {
            this.requestSearch(page)
        },
        searchEvent() {
            this.requestSearch()
        },
        async requestSearch(page) {
            this.tableLoading = true;
            let dataSet = await getUserList(this.userName, this.isForbidden, page,this.departmentCode);
            let res = dataSet.dataSet
            this.list = res.list;
            this.pagination = {
                count: res.count,
                pageNumber: res.pageNumber,
                pageSize: res.pageSize,
                pages: res.pages
            }
            this.tableLoading = false;
        },
        async getDictDetailList() {
            let typeCode = 'IS_FORBIDDEN';
            let res = await getDictDetailList(typeCode);
            this.statusMap = setDictMap(res);
        },
        statusEvent(index) {
            this.selItem = this.list[index]
            if (this.selItem.isForbidden === false) {
                this.prohibitVisible = true
            } else {
                this.enableVisible = true
            }
        },
        //启用
        async enableUser() {
            let res = await userStatus(this.selItem.id, false)
            if (res.code==200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: '启用成功',
                    type: 'success'
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '启用失败',
                    type: 'error'
                });
            }
            this.enableVisible = false
        },
        //禁用
        async prohibitUser() {
            let res = await userStatus(this.selItem.id, true);
            if (res.code==200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: '禁用成功',
                    type: 'success'
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '禁用失败',
                    type: 'error'
                });
            }
            this.prohibitVisible = false
        },
        hideRoleEvent(){
            this.roleVisible = false
        },
        //获取角色
        async roleList() {
          let res = await this.$get("/admin/getOpRoleList")
            res.roleList.forEach(item => {
                this.roles.push({
                    label: item.roleName,
                    key: item.id
                })
            })
        },
        showRole (index) {
            let item = this.list[index]
            this.currentId = item.id
            this.roleVisible = true
        },
        //获取组织机构
        async departmentList() {
            this.treeLoading = true;
             let res = await this.$get("/admin/getStoreList")
            let data=  dataFormat(this.options,res)
            this.nodes = data.list
            this.treeLoading = false
        },

        addEvent() {
            this.addVisible = true;
        },
        editEvent(index) {
            this.editItem = this.list[index]
            this.editVisible = true;
        },
        removeEvent(index) {
            this.selItem = this.list[index]
            this.delVisible = true;
        },
        //删除用户
        async delUser() {
            let res = await deleteUser(this.selItem.id)
            if (res.code==200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.delVisible = false;
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        closeInsert() {
            this.addVisible = false;
        },

        closeEdit() {
            this.editVisible = false;
        },

        addUserSuccess() {
            this.closeInsert();
            this.searchEvent();
        },

        editUserSuccess() {
            this.closeEdit();
            this.searchEvent();
        }
    },
    components: {
        Insert,
        vTree,
        Edit,
        AddRole
    }
};
</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  .user-container{
    display: flex;
  }
  .tree-container {
    margin-right: 10px;
    padding:10px;
    flex:0 0 280px;
    width: 280px;
    height: 400px;
    overflow-y:auto;
    border:1px #ebeef5 solid;
  }
  .table-container{
    flex:1;
    display: inline-block;
  }
  .e-card-body {
    padding: 25px !important;
}
  .off {
    background-color: #fff;
  }
  .on {
    background-color: #ececea;
  }
</style>
