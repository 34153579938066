<template>
    <el-dialog title="分配角色" :visible.sync="roleVisible" width="625px" :before-close="closeEvent">
        <el-transfer
            filterable
            :titles="titles"
            filter-placeholder="请输入角色名称"
            v-model="checkedRole"
            :data="roles">
        </el-transfer>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="saveEvent">确 定</el-button>
                <el-button size="small" @click="closeEvent">取 消</el-button>
            </span>
    </el-dialog>
</template>

<script>

    export default {
        data (){
            return {
                titles: ['角色','已选'],
                checkedRole: []
            }
        },
        props: {
            roleVisible: Boolean,
            roles: Array,
            id: String
        },
        async created () {
          this.checkedRole = await this.$post("/admin/opCheck_roles", {userId:this.id})
        },
        methods: {
            closeEvent () {
                this.$emit('hideRoleEvent')
            },
            async saveEvent(){
                if(!this.checkedRole.length){
                    this.$message({
                        showClose: true,
                        message: '请至少分配一个角色',
                        type: 'error'
                    });
                    return;
                }
            let data = {
                userId: this.id,
               roleIdStr: JSON.stringify(this.checkedRole)
                };
              let res = await this.$post("/admin/save_opUserRoleGroup", data)
                if (res.code==200) {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.closeEvent()
                }
            }
        }
    }
</script>
