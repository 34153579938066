<template>
<el-dialog title="新增" width="600px" :visible.sync="addVisible" :before-close="closeEvent">
    <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="用户名:" required >
            <el-input v-model="userName" maxlength="12" style="width: 425px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="姓名:" required >
            <el-input v-model="name" maxlength="8" style="width: 425px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="邮箱:" required>
            <el-input v-model="email" maxlength="24" style="width: 425px" show-word-limit></el-input>
        </el-form-item>
      <el-form-item size="small" label="类别:" required>
        <el-select v-model="sort" style="width: 425px"placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="saveUser">确 定</el-button>
    <el-button size="small" @click="closeEvent">取 消</el-button>
  </span>
</el-dialog>
</template>
<script>
  import {saveUser} from '../../../api/sys/user'
  export default {
    data () {
      return {
          userName: '',
          name:'',
          email:'',
          sort:'1',
          options:[
          {
            label:'门店',
            value:'1',
          },{
              label:'前台',
              value:'2',
          }]
      }
    },
    props: {
      departmentId:String,
      departmentCode:String,
      roleItem: Array,
      addVisible: Boolean
    },
    methods: {
      closeEvent () {
        this.$emit('closeInsert')
      },
      async saveUser () {
        let data = {
            loginName: this.userName,
            email: this.email,
            name: this.name,
            sort: this.sort,
            orgId: this.departmentId,
            departmentCode:this.departmentCode
        };
      if(!this.userName) {
          this.$message({
              showClose: true,
              message: '请输入用户名',
              type: 'error'
          });
          return
      }

      if(!this.name) {
          this.$message({
              showClose: true,
              message: '请输入性名',
              type: 'error'
          });
          return
      }


        let res = await saveUser(data);
        if (res.code==200){
            this.$message({
              showClose: true,
              message: res.message,
              type: 'success'
            });
            this.$emit('addUserSuccess')
        }else {
            this.$message({
                showClose: true,
                message: res.message,
                type: 'error'
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>
